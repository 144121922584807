import './App.css';
import { useEffect, useState } from 'react'
import { Search, Trash, FileSpreadsheet, Info } from 'lucide-react'
import { Button as MUIButton, TextField, Tooltip, Box, Card, CardContent, Typography } from '@mui/material'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import axios from 'axios';

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [notFoundClauses, setNotFoundClauses] = useState([]);
  const [foundClauses, setFoundClauses] = useState([]);

  useEffect(()=>{
  }, [foundClauses]);

  const handleClear = () => {
    setNotFoundClauses([]);
    setFoundClauses([]);
    setSearchTerm("");
  }

  const handleSearch = () => {
    setLoading(true);
    axios.post('https://formatcheck.gradschool.mtu.edu/clause-finder-api/api/find-clauses', { "clause_text": searchTerm })
      .then(response => {
        if (response.data && response.data.results) {
          setFoundClauses(response.data.results);
          setNotFoundClauses(response.data.not_found_clauses);
        } else {
          setFoundClauses([]);
          setNotFoundClauses([]);
        }
        setLoading(false); // Hide the loading state when done
      })
      .catch(error => {
        setLoading(false); // Hide loading state on error
      });
  }

  const handleDownload = async () => {
    try {
      const response = await fetch('https://formatcheck.gradschool.mtu.edu/clause-finder-api/api/download-excel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download the file');
      }

      // Get the file from the response
      const blob = await response.blob();
      
      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'data.xlsx'; // Specify the file name
      link.click();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }    
  }

  return (
    <div className='center-div'>
      {loading && <div className='loader'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><linearGradient id="a3"><stop offset="0" stop-color="#000000" stop-opacity="0"></stop><stop offset="1" stop-color="#000000"></stop></linearGradient><circle fill="none" stroke="url(#a3)" stroke-width="15" stroke-linecap="round" stroke-dasharray="0 44 0 44 0 44 0 44 0 360" cx="100" cy="100" r="70" transform-origin="center"><animateTransform type="rotate" attributeName="transform" calcMode="discrete" dur="2" values="360;324;288;252;216;180;144;108;72;36" repeatCount="indefinite"></animateTransform></circle></svg>
        </div>}
      <h1 className='text-center'>Clause Finder</h1>
      <div className='main-box'>
        {/* Search Section */}
        <div className='width-50 width-30 v-sb'>
          <Card variant="outlined" sx={{ boxShadow: 3 }} className='h-70'>
            <Typography className="ml-2 mv-4" variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
              Search Clauses &nbsp;
              <Tooltip title="Enter clause numbers or keywords to search" arrow>
                <Info  />
              </Tooltip>
            </Typography>
            <CardContent className='h-70'>
              <TextField
                className='h-90'
                variant="outlined"
                multiline
                rows={6}
                placeholder="Enter clauses here"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth                
              />
              <MUIButton onClick={handleSearch} disabled={searchTerm === ""} fullWidth sx={{ color: 'white', mt: 2, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}>
                <Search /> Search
              </MUIButton>
              <MUIButton onClick={handleClear} fullWidth sx={{ color: 'white', mt: 2, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}>
                <Trash /> Clear
              </MUIButton>
            </CardContent>
          </Card>

          {/* Small Summary Card */}
          <Card variant="outlined" sx={{ boxShadow: 3, mt: 2 }}>
            <Typography className="ml-2" variant="h6">Search Summary</Typography>
            <CardContent>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, textAlign: 'center' }}>
                <Box sx={{ bgcolor: 'grey.100', borderRadius: 1, p: 2 }}>
                  <Typography className="ml-2" variant="h5">{foundClauses.length + notFoundClauses.length}</Typography>
                  <Typography className="ml-2" variant="caption" color="textSecondary">Total</Typography>
                </Box>
                <Box sx={{ bgcolor: 'green.100', borderRadius: 1, p: 2 }}>
                  <Typography className="ml-2" variant="h5" color="success.main">{foundClauses.length}</Typography>
                  <Typography className="ml-2" variant="caption" color="success.main">Found</Typography>
                </Box>
                <Box sx={{ bgcolor: 'red.100', borderRadius: 1, p: 2 }}>
                  <Typography className="ml-2" variant="h5" color="error.main">{notFoundClauses.length}</Typography>
                  <Typography className="ml-2" variant="caption" color="error.main">Not Found</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </div>

        {/* Results Section */}
        <div className='width-50 width-70'>
          {/* Not Found Clauses */}
          <Card variant="outlined" sx={{ boxShadow: 3 }}>
              <Typography className="ml-2" variant="h6">Clauses Not Found</Typography>
            <CardContent>
              <Box sx={{ overflowY: 'auto' }} className="m-height">
                {notFoundClauses.map((clause, index) => (
                  <Typography className="ml-2" key={index} variant="body2" color="textSecondary">{clause}</Typography>
                ))}
              </Box>
            </CardContent>
          </Card>

          {/* Found Clauses */}
          <Card variant="outlined" sx={{ boxShadow: 3 }} className='m-height-57'>
            <Typography className="ml-2" variant="h6">Found Clauses</Typography>
            <CardContent>
              <Box className='table-container' sx={{ overflowY: 'auto' }}>
                <Table className='mw-1500 table-ui'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Accept</TableCell>
                      <TableCell>Required Flow Down</TableCell>
                      <TableCell>IN CONTRACT</TableCell>
                      <TableCell>FAR</TableCell>
                      <TableCell>PREFERRED ALTERNATES</TableCell>
                      <TableCell>CLAUSE TITLE</TableCell>
                      <TableCell>DATE</TableCell>
                      <TableCell>PRESCRIBED</TableCell>
                      <TableCell>COMMENT</TableCell>
                      <TableCell>Explanation</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {foundClauses.map((clause, index) => (
                      <TableRow key={index}>
                        <TableCell>{clause.accept ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{clause.requiredFlowDown || '-'}</TableCell>
                        <TableCell>{clause.inContract || '-'}</TableCell>
                        <TableCell>{clause.clause || '-'}</TableCell>
                        <TableCell>{clause.preferredAlternates || '-'}</TableCell>
                        <TableCell>{clause.clauseTitle || '-'}</TableCell>
                        <TableCell>{clause.date || '-'}</TableCell>
                        <TableCell>{clause.prescribed || '-'}</TableCell>
                        <TableCell>{clause.comment || '-'}</TableCell>
                        <TableCell>{clause.explanation || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Download Button */}
      <div>
        <MUIButton onClick={handleDownload} className='fl-r m-10' sx={{ color: 'white', bgcolor: 'success.main', '&:hover': { bgcolor: 'success.dark' } }}>
          <FileSpreadsheet /> Download Excel
        </MUIButton>
      </div>
    </div>
  );
}

export default App;
